body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #f5f4f0;
}

body.no-scroll {
    overflow-y: hidden;
    margin-right: 16px;
}
